<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="预约用户：">
        <el-input size="small" placeholder="请输入预约用户" v-model="form.nick_name"></el-input>
      </el-form-item>
      <el-form-item label="预约人：">
        <el-input size="small" placeholder="请输入预约人姓名/手机号" v-model="form.order_word"></el-input>
      </el-form-item>
      <el-form-item label="诊断时间">
        <el-date-picker v-model="form.time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="45px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="预约用户">
        <template v-slot="{ row }">
            <div class="row">
                <el-image :src="row.avatar" class="avatar" v-if="row.avatar"></el-image>
                <div >{{row.nick_name}}</div>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="服务主图" align="center">
        <template v-slot="{ row }">
            <el-image :src="row.images" class="s_image"  :preview-src-list="new Array(row.images)"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="service_name" label="服务名称">
      </el-table-column>
      <el-table-column label="预约人" >
        <template v-slot="{ row }">
            <div>{{row.name}}</div>
            <div>{{row.mobile}}</div>
        </template>
      </el-table-column>
       <el-table-column prop="staff_name" label="服务人员" align="center">
      </el-table-column>
      <el-table-column label="诊断时间" align="center" >
          <template v-slot="{ row }">
            <div>{{row.finish_time | getDateformat}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="160">
        <template v-slot="{ row }">
          <div class="btn">
             <el-button type="text" size="small" @click="editList(row.id)">编辑诊断</el-button>
            <el-button type="text" size="small" @click="delList(row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components:{
        Paging
    },
  data() {
    return {
        form:{
            page:1,
            rows:10,
            nick_name:'',
            order_word:'',
            time:'',
        },
        list:[],
        total_number:0
    };
  },
    created() {
        this.getList()
    },
    filters:{
        getDateformat:getDateformat
    },
  methods: {
    updateData(val, status){
        if (status == 0) {
            this.form.rows = val;
            this.getList();
        } else {
            this.form.page = val;
            this.getList();
        }
    },
    getList(){
        let data = {
            page:this.form.page,
            rows:this.form.rows,
            nick_name:this.form.nick_name,
            order_word:this.form.order_word,
        }
        if(this.form.time){
            data.starttime = this.form.time[0]/1000
            data.endtime = this.form.time[1]/1000
        }
        this.$axios.post(this.$api.beauty.diagnosisList, data).then(res => {
            if (res.code == 0) {
                this.total_number = res.result.total;
                let list = res.result.list
                this.list = list;
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    search(){
        this.form.page = 1;
        this.getList();
    },
    cancelSearch() {
        this.form = {
            page:1,
            rows:10,
            nick_name:'',
            order_word:'',
            time:''
        };
        this.getList();
    },
    delList(id){
        this.$confirm('确定删除此诊断单?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
        .then(() => {
            this.$axios.post(this.$api.beauty.diagnosisDel, {id}).then(res => {
                if (res.code == 0) {
                    this.$message.success('删除成功');
                    this.getList()
                } else {
                    this.$message.error(res.msg);
                }
            });
        })
        .catch(() => {});
    },
    editList(id){
        this.$router.push({path:'/reserve/diagnosisAdd?id='+id+'&is_look=0'})
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.row{
    display: flex;
    align-items: center;
    .avatar{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .row_left{
        margin-right: 10px;
    }
}
.s_image{
    width: 70px;
    height: 70px;
}
</style>
